var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Responsive bootstrap Pagination")]),
              _c("div", { staticClass: "card-header-actions" }, [
                _c(
                  "a",
                  {
                    staticClass: "card-header-action",
                    attrs: {
                      href: "https://coreui.io/vue/docs/components/pagination",
                      rel: "noreferrer noopener",
                      target: "_blank"
                    }
                  },
                  [_c("small", { staticClass: "text-muted" }, [_vm._v("docs")])]
                )
              ])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("h6", [_vm._v("Default")]),
              _c("CPagination", {
                attrs: {
                  "active-page": _vm.currentPage,
                  pages: 10,
                  responsive: ""
                },
                on: {
                  "update:activePage": function($event) {
                    _vm.currentPage = $event
                  },
                  "update:active-page": function($event) {
                    _vm.currentPage = $event
                  }
                }
              }),
              _c("br"),
              _c("h6", [_vm._v("Small")]),
              _c("CPagination", {
                attrs: {
                  size: "sm",
                  "active-page": _vm.currentPage,
                  pages: 10
                },
                on: {
                  "update:activePage": function($event) {
                    _vm.currentPage = $event
                  },
                  "update:active-page": function($event) {
                    _vm.currentPage = $event
                  }
                }
              }),
              _c("br"),
              _c(
                "div",
                [
                  _c("h6", [_vm._v("Large")]),
                  _c("CPagination", {
                    attrs: {
                      size: "lg",
                      "active-page": _vm.currentPage,
                      pages: 10,
                      responsive: ""
                    },
                    on: {
                      "update:activePage": function($event) {
                        _vm.currentPage = $event
                      },
                      "update:active-page": function($event) {
                        _vm.currentPage = $event
                      }
                    }
                  }),
                  _c("br")
                ],
                1
              ),
              _c("div", [_vm._v("currentPage: " + _vm._s(_vm.currentPage))])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Pagination ")]),
              _c("small", [_vm._v("alignment")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("h6", [_vm._v("Left alignment (default)")]),
              _c("CPagination", {
                attrs: { "active-page": _vm.currentPage, pages: 10 },
                on: {
                  "update:activePage": function($event) {
                    _vm.currentPage = $event
                  },
                  "update:active-page": function($event) {
                    _vm.currentPage = $event
                  }
                }
              }),
              _c("br"),
              _c("h6", [_vm._v("Center alignment")]),
              _c("CPagination", {
                attrs: {
                  align: "center",
                  pages: 10,
                  "active-page": _vm.currentPage
                },
                on: {
                  "update:activePage": function($event) {
                    _vm.currentPage = $event
                  },
                  "update:active-page": function($event) {
                    _vm.currentPage = $event
                  }
                }
              }),
              _c("br"),
              _c("h6", [_vm._v("Right (end) alignment")]),
              _c("CPagination", {
                attrs: {
                  align: "end",
                  "active-page": _vm.currentPage,
                  pages: 10
                },
                on: {
                  "update:activePage": function($event) {
                    _vm.currentPage = $event
                  },
                  "update:active-page": function($event) {
                    _vm.currentPage = $event
                  }
                }
              }),
              _c("br"),
              _c("div", [_vm._v("currentPage: " + _vm._s(_vm.currentPage))])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }